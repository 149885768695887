.containerLo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  /* Increase the height to make the container bigger */
}

.paperLo {
  width: 400px;
  height: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.paperLo h3 {
  margin-bottom: 20px;
  text-align: center;
}

.paperLo p {
  color: #ff0000;
  margin-bottom: 10px;
  text-align: center;
}

.paperLo input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.btn-global {
  width: 100%;
  padding: 10px;
  background-color: #1291c7;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-global:hover {
  background-color: #cc0000;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.d-flex div {
  margin-right: 5px;
}

.d-flex button {
  padding: 10px;
  background-color: #3bb143;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.d-flex button:hover {
  background-color: #299e36;
}

.btn-g {
  width: 100%;
  padding: 10px;
  background-color: #1291c7;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
}

.btn-gs {
  width: 50%;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 13px;
}

#account-Register {
  font-size: 13px;
  font-weight: bold;
  color: #ff0000;

}