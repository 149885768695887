.mb-10 {
    margin-bottom: 10px
}

.col {
    display: flex;
    flex-direction: column;
}

.mr-2 {
    margin-right: 2%;
}

.mb-2 {
    margin-bottom: 2%;
}

.w-80 {
    width: 80%;
}

.d-flex {
    display: flex;
}

.text-btn {
    text-decoration: underline;
    color: rgb(19, 115, 241);
    cursor: pointer;
    display: flex;
}

.w-100 {
    width: 100%;
}

.mt-40 {
    margin-top: 40px;
}

.p-4 {
    padding: 4%;
}

.ml-2 {
    margin-left: 2%;
}




.btn-global {
    margin-bottom: 2%;
    background-color: rgb(78, 162, 227) !important;
    border: 0;
    width: 20%;
    border-radius: .5rem;
    box-sizing: border-box;
    color: white;
    font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: .75rem 1rem;
    text-align: center;
    text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-38:hover {
    background-color: rgb(249, 250, 251);
}

.button-38:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.button-38:focus-visible {
    box-shadow: none;
}

.bg-main {
    background-color: rgb(5, 5, 126);
}

.bg-cart {
    background-color: rgb(95, 95, 220);
}