/* categoryDetails.module.css */

.container {
  width: 50%;
  padding: 7%;
}

.title {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5%;
}


.listItem {
  background-color: white;
  border-radius: 22px;
  padding: 4%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin-top: 5%;
  margin-bottom: 5%;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.buttonText {
  margin: 0 10px;
  font-weight: bold;
}

.addToCartButton {
  background-color: rgb(25, 130, 179);
}

.btn{
  @apply !text-white font-bold !bg-[#4EA2E3] py-2 w-full rounded;
}

.viewCartButton {
  background-color: rgb(41, 126, 230);
}