.containerCo {
  max-width: 960px;
  margin: 0 auto;
}

.boxCo {
  margin-top: 50px;
}

.titleCo {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.grid-containerCo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paperCo {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.paper-titleCo {
  font-size: 20px;
  margin-bottom: 10px;
}

.paper-textCo {
  font-size: 16px;
  margin-bottom: 20px;
}

.input-fieldCo,
.textarea-fieldCo {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
}

.btnCo {
  box-shadow: inset 0px -3px 7px 0px #ababab;
  background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}

.btnCo:hover {
  background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
  background-color: #e9e9e9;
}

.btnCo:active {
  position: relative;
  top: 1px;
}
