.orders-header {
    margin-bottom: 4vh;
}

.orders-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-orders {
    display: flex;
    background-color: #f8f9fa;
    padding: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Center the text horizontally */
}

.loading-spinner {
    margin-top: 20vh;
}



.order-item {
    margin-bottom: 6vh;
}


.order-info>div {
    flex: 1 1 80vh;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
}

.order-info h4 {
    margin-bottom: 10px;
}

.quantity {
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity span {
    margin: 0 10px;
}

.quantity-icons {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.quantity-icons button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
}

.btn-global {
    width: 200px;
    margin-top: 10px;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.btn-global:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .orders-list {
        max-width: 100%;
        /* Adjust the width for smaller screens */
    }
}