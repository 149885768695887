body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color:#f0f0f0;
    /* Set your desired background color */
    color: #333;
    /* Set your desired text color */
}

.container {
    display: flex;
    justify-content: center;
    align-items: start;
    height: 80vh;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 60vh;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.form-title {
    margin-bottom: 20px;
    text-align: center;
}

.form-error {
    color: #ff0000;
    margin-bottom: 10px;
    text-align: center;
}

.form-field {
    margin-bottom: 15px;
}

.form-field label {
    display: block;
    margin-bottom: 5px;
}

.form-field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #1291c7;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0a9647;
}